/* AboutPage.css */

:root {
  --primary-color: #0077be;
  --secondary-color: #f9f9f9;
  --text-color: #333;
  --accent-color: #6bd5ff;
}
.smeadit-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/InpersonPhotos/Norma.jpg') center/cover no-repeat fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.smeadit-hero-content {
  text-align: center;
  color: #fff;
  max-width: 800px;
  padding: 0 20px;
}

.smeadit-hero-content h1 {
  font-size: 4.5rem;
 
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 30px;
  line-height: 1.2;
  position: relative;
  display: inline-block;
}

.smeadit-hero-content h1::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 80px;
  height: 4px;
  background-color: #0077be;
  transform: translateX(-50%);
}

.smeadit-hero-content p {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  margin-top: 30px;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .smeadit-hero-content h1 {
    font-size: 3rem;
    letter-spacing: 5px;
  }
  
  .smeadit-hero-content p {
    font-size: 1.4rem;
  }
}
.smeadit-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 100px 20px;
}

.smeadit-section {
  margin-bottom: 120px;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.smeadit-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.smeadit-about-section h2 {
  font-size: 4rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.smeadit-about-section h2::after {
  content: '';
  display: block;
  width: 120px;
  height: 5px;
  background: var(--accent-color);
  margin: 25px auto 0;
  border-radius: 2.5px;
}

.smeadit-about-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
}

.smeadit-about-detail p {
  flex: 1;
  font-size: 1.3rem;
  line-height: 1.9;
  color: var(--text-color);
  padding-right: 60px;
}

.smeadit-about-detail img {
  flex: 1;
  max-width: 550px;
  border-radius: 15px;
  box-shadow: 0 15px 40px rgba(0,0,0,0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.smeadit-about-detail img:hover {
  transform: scale(1.05) rotate(1deg);
  box-shadow: 0 20px 50px rgba(0,0,0,0.2);
}

.smeadit-subsection {
  background: var(--secondary-color);
  padding: 50px;
  border-radius: 15px;
  margin-bottom: 50px;
  box-shadow: 0 8px 25px rgba(0,0,0,0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.smeadit-subsection::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  background: var(--accent-color);
  opacity: 0.1;
  border-radius: 50%;
  transition: transform 0.5s ease;
}

.smeadit-subsection:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0,0,0,0.12);
}

.smeadit-subsection:hover::before {
  transform: scale(5);
}

.smeadit-subsection h3 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 25px;
  position: relative;
  display: inline-block;
}

.smeadit-subsection h3::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50%;
  height: 3px;
  background: var(--accent-color);
  transition: width 0.3s ease;
}

.smeadit-subsection:hover h3::after {
  width: 100%;
}

.smeadit-subsection p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--text-color);
}

.smeadit-timeline {
  position: relative;
  padding: 50px 0;
}

.smeadit-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background: var(--primary-color);
  transform: translateX(-50%);
}

.smeadit-timeline-item {
  margin-bottom: 50px;
  position: relative;
}

.smeadit-timeline-content {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  width: calc(50% - 40px);
  position: relative;
}

.smeadit-timeline-content::after {
  content: '';
  position: absolute;
  top: 20px;
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  border-radius: 50%;
}

.smeadit-timeline-item:nth-child(odd) .smeadit-timeline-content {
  left: 0;
}

.smeadit-timeline-item:nth-child(odd) .smeadit-timeline-content::after {
  right: -50px;
}

.smeadit-timeline-item:nth-child(even) .smeadit-timeline-content {
  left: 50%;
}

.smeadit-timeline-item:nth-child(even) .smeadit-timeline-content::after {
  left: -50px;
}

/* Existing styles remain unchanged */

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .smeadit-hero-content h1 {
    font-size: 3.5rem;
  }
  
  .smeadit-hero-content p {
    font-size: 1.4rem;
  }
  
  .smeadit-container {
    padding: 60px 15px;
  }
  
  .smeadit-about-section h2 {
    font-size: 3rem;
  }
  
  .smeadit-about-detail {
    flex-direction: column;
  }
  
  .smeadit-about-detail img {
    margin-top: 30px;
    max-width: 100%;
  }
  
  .smeadit-subsection {
    padding: 30px;
    
  }
  
  .smeadit-subsection h3 {
    font-size: 2rem;
  }
  
  .smeadit-timeline::before {
    left: 20px;
    display: none;
  }
  .smeadit-timeline{
    display: none;
  }
  .smeadit-timeline-content {
    width: calc(100% - 50px);
    float: right;
    margin-left: 50px;
    display: none;
  }
  
  .smeadit-timeline-item:nth-child(odd) .smeadit-timeline-content::after,
  .smeadit-timeline-item:nth-child(even) .smeadit-timeline-content::after {
    left: -35px;
    top: 15px;
  }
}

