* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
}

.services3sections-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}


.services3sections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.services3sections-category {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 25px;
  width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.services3sections-category:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.services3sections-category-title {
  font-size: 2rem;
  margin-bottom: 10px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  color: #007bff;
}

.services3sections-category-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #555;
}

.services3sections-service-list {
  padding: 0;
  list-style: none;
}

.services3sections-service-item {
  font-size: 1.1rem;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  transition: color 0.3s;
}

.services3sections-service-item:hover {
  color: #007bff;
}

.services3sections-find-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.services3sections-find-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .services3sections-page {
    padding: 10px;
  }

  .services3sections-hero-image {
    height: 300px;
  }

  .services3sections-hero-title {
    font-size: 2rem;
  }

  .services3sections-hero-subtitle {
    font-size: 1.2rem;
  }

  .services3sections-hero-promo {
    font-size: 1rem;
  }

  .services3sections-hero-text {
    width: calc(100% - 20px);
    padding: 10px;
  }

  .services3sections-container {
    flex-direction: column;
    align-items: center;
  }

  .services3sections-category {
    width: 90%;
  }

  .services3sections-hero-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .services3sections-category {
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) {
  .services3sections-container {
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  .services3sections-container {
    justify-content: space-between;
  }

  .services3sections-category {
    width: 30%;
  }
}
.services-hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 60px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
}

.services-hero-content {
  flex: 1;
  padding-right: 60px;
  z-index: 2;
}

.services-hero-title {
  font-size: 4rem;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
}

.services-hero-subtitle {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 15px;
  line-height: 1.4;
  font-weight: 400;
}

.services-hero-promo {
  font-size: 1.3rem;
  color: #e74c3c;
  margin-bottom: 30px;
  font-weight: 600;
  padding: 10px 20px;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 10px;
  display: inline-block;
}

.services-hero-cta {
  display: flex;
  gap: 20px;
}

.cta-button {
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-align: center;
}

.cta-button.primary {
  background-color: #3498db;
  color: white;
  box-shadow: 0 4px 6px rgba(52, 152, 219, 0.3);
}

.cta-button.secondary {
  background-color: transparent;
  color: #3498db;
  border: 2px solid #3498db;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.services-hero-image {
  flex: 1;
  text-align: right;
  z-index: 2;
}

.services-hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.services-hero-image img:hover {
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .services-hero-section {
    flex-direction: column;
    padding: 60px 40px;
  }

  .services-hero-content {
    padding-right: 0;
    margin-bottom: 40px;
    text-align: center;
  }

  .services-hero-title {
    font-size: 3rem;
  }

  .services-hero-subtitle {
    font-size: 1.3rem;
  }

  .services-hero-cta {
    justify-content: center;
  }

  .services-hero-image {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .services-hero-section {
    padding: 40px 20px;
  }

  .services-hero-title {
    font-size: 2.5rem;
  }

  .services-hero-subtitle {
    font-size: 1.1rem;
  }

  .services-hero-promo {
    font-size: 1.1rem;
  }

  .services-hero-cta {
    flex-direction: column;
  }

  .cta-button {
    width: 100%;
  }
}
