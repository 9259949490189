/* MaintenancePage.css */

/* Center the content vertically and horizontally */
.maintenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
  }
  
  /* Style the content within the container */
  .maintenance-content {
    text-align: center;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Style the heading */
  .maintenance-heading {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333; /* Dark text color */
    text-transform: uppercase;
  }
  
  /* Style the paragraph text */
  .maintenance-text {
    font-size: 20px;
    color: #555; /* Slightly darker text color */
    line-height: 1.5;
  }
  
  /* Style the countdown container */
  .countdown-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style the countdown text */
  .countdown-text {
    font-size: 18px;
    color: #777; /* Lighter text color */
    margin-right: 10px;
  }
  
  /* Style the countdown time */
  .countdown-time {
    font-size: 32px;
    color: #ff5733; /* Highlighted text color */
    font-weight: bold;
    border: 2px solid #ff5733;
    padding: 10px 20px;
    border-radius: 5px;
  }
  