.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0 15px;
  padding-top: 150px;
}

.contact-wrapperer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 15px;
}

.contact-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-link {
  padding: 20px 15px;
  background: white; /* Lighter background for a subtle look */
  color: #102a43;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow for a smoother appearance */
  transition: all 0.3s ease;
}

.contact-link a {
  color: #6875f5; /* Use brand color for link for better integration */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.contact-link:hover {
  background-color: #6875f5; /* Brand color on hover */
  color: #ffffff; /* White text color on hover */
}

.contact-link:hover a {
  color: inherit; /* Ensure the link color matches the text color on hover */
}

.contact-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.contact-container h1 {
  color: #102a43;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.contact-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-container input,
.contact-container textarea {
  padding: 15px;
  border: 1px solid #d0d9e8;
  border-radius: 8px;
  background: #f7f9fc;
}

.contact-container input:focus,
.contact-container textarea:focus {
  border-color: #6875f5;
  background: #fff;
  box-shadow: 0 0 0 4px rgba(104, 117, 245, 0.2);
}

.contact-container button {
  padding: 15px 30px;
  background-color: #6875f5;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
}

.contact-container button:hover {
  background-color: #5a67d8;
  box-shadow: 0 12px 24px rgba(90, 103, 216, 0.2);
}

.success-message {
  padding: 15px;
  background-color: #d1e7dd;
  color: #0f5132;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  display: none; /* Initially hidden */
}

@media (max-width: 768px) {
  .contact-link {
    font-size: 1rem; /* Adjust font size for smaller screens */
    padding: 15px; /* Adjust padding */
  }

  .contact-links {
    flex-direction: column; /* Stack links vertically on smaller screens */
    gap: 10px; /* Reduce gap */
  }

  .contact-container {
    padding: 20px; /* Reduce padding for smaller screens */
  }
}

input:hover, textarea:hover {
  background: #fff;
  border: 1px solid #6875f5;
}
