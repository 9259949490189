* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
}

.webinars-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.webinars-header {
  background: #007bff;
  color: white;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.webinars-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.webinars-header p {
  font-size: 1.2rem;
}

.webinars-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.webinars-info-section {
  background: white;
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 250px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.webinars-info-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.info-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 10px;
}

.webinars-info-section h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

.webinars-info-section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.webinars-signup {
  background: white;
  border-radius: 15px;
  padding: 30px;
  margin: 40px 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.webinars-signup h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff;
}

.signup-form .form-group {
  margin-bottom: 20px;
}

.signup-form label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333;
}

.signup-form input {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.signup-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-button:hover {
  background-color: #0056b3;
}

.webinars-footer {
  margin-top: 40px;
  padding: 20px;
  background: #007bff;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
