.modals-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

#modals {
  background: linear-gradient(to right, #ffffff, #e6f1ff);
  /* background-image: url('../../assets/modalBackground.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 650px;
  margin: 20px;
  animation: modalFadeIn 0.3s;
  align-content: center;
  align-items: center;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

#modals h2 {
  /* color: #2c3e50; */
  color: #003366;
  font-size: 26px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}
#modals p {
  color: #003366;
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}
#modals form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

#modals form label {
  font-size: 16px;
  color: #003366;
  font-weight: 500;
}

#modals form input[type='text'],
#modals form input[type='email'],
#modals form input[type='tel'] {
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  font-size: 15px;
  transition: border-color 0.3s;
}

#modals form input[type='text']:focus,
#modals form input[type='email']:focus,
#modals form input[type='tel']:focus {
  border-color: #3498db;
  outline: none;
}

#modals button {
  background-color: #3498db;
  align-self: center;
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: background-color 0.3s, transform 0.2s;
}

#modals button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

#modals button:active {
  transform: translateY(1px);
}

.modal-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.modal-item {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #ffffff;
  margin-bottom: 15px;
}

.modal-icon {
  font-size: 28px;
  color: #3f34db;
}

.confirmation-message {
  color: #27ae60;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.service-btn {
  background-color: #8e44ad;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-weight: 500;
}

.service-btn:hover {
  background-color: #9b59b6;
}

video {
  width: 100%;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  #modals {
      width: 95%;
      padding: 20px;
  }

  #modals h2 {
      font-size: 22px;
  }
}

