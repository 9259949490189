/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
*/

body{
  background: linear-gradient(to right, #ffffff, #e6f1ff);
}
html {
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
}
.Homehero {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  color: #111a39;
}

.Homehero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../assets/newbackdrop.png') no-repeat center center;
  background-size: cover;
  z-index: -1;
}

.Homehero-content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  text-align: center;
}

.info-bubbles {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}




.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 3%; /* Adjust as needed for spacing between buttons */
}

.servicehomepagesection-section {
  position: relative;
 margin-top: 5%;
  z-index: 2;
}



.ContainerStarter{
  position: relative;
  min-height: 110vh;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(135deg, #6e8efb, #ffffff); */
  background-size: 200% 200%;
  animation: gradientShift 10s ease infinite;
  color: #003366; /* Deep blue for contrast */
  overflow: hidden;
}



@media (max-width: 758px) {

  .ContainerStarter{
  
    padding-top: 80px;

  }
} 


@keyframes gradientShift {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}


.Homehero-content h1 {
  font-size: 6rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  animation: fadeInDown 1.2s ease-out;
}



.Homehero p {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1.2s ease-out;
}

.cta-button {
  padding: 15px 30px;
  background-color: #6e8efb;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;


  margin-top: 4%;
  
}

.cta-button:hover {
  /* transform: translateY(-3px); */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Additional animations */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




.info-bubbles {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  
}

.info-bubble:hover {
  transform: scale(1.05);
  background: #c6ced2;
}

.bubble-icon {
  font-size: 2.5rem;
  color: #6e8efb;
  margin-bottom: 10px;
}

.info-bubble h3 {
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 10px;
}

.info-bubble p {
  font-size: 1.2rem;
  color: #000000;
}

@keyframes popIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}









.info-bubbles {
  flex-wrap: wrap;
  justify-content: space-around;

}

.info-bubble {


  background: white;
  color: #000000;
  max-width: 300px ;
  box-shadow: 0px 5px 15px rgba(0, 51, 102, 0.2);

  padding: 20px;
  
  border-radius: 15px;
  text-align: center;
  animation: popIn 1s ease;
  transition: transform 0.3s ease, background 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center; 



  box-shadow: 0px 5px 15px rgba(0, 51, 102, 0.2);

}




.cta-button:hover {
  background: linear-gradient(45deg, #5a7bda, #274acc);
  border: 2px solid #1a8cff;
}


@media (max-width: 768px) {
  .Homehero {
    min-height: auto;
    padding: 800px 10px; 
  
    background: linear-gradient(to right, #ffffff, #e6f1ff);
  
  }
  .Homehero h1 {
    font-size: 3rem;

  }
  .services-section{
    margin-top: 130px;
  }
  .info-bubble {
    margin-bottom: 20px; 
  }
  .cta-button {
    font-size: 0.9rem; 
  }
} 

@media (max-width: 368px) {
  .cta-button {
    font-size: 0.8rem;
    padding: 15px 20px;
  }

}

.services-section {
  text-align: center;
  /* padding: 50px 0; */
  background: linear-gradient(to right, #ffffff, #e6f1ff);
  color: #333;
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
}

.service-item {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 300px;
  transition: transform 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 3rem;
  color: #1a8cff;
  margin-bottom: 15px;
}

.service-item h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

.service-item .ptext {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  margin-bottom: 15px;
}

.service-btn {
  background-color: #1a8cff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-btn:hover {
  background-color: #0f7ae5;
}

@media (max-width: 768px) {
  /* .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-item {
    width: 80%;
  } */
}





.Homehero h1 {
  font-size: 5rem; 
  margin-bottom: 0.5rem;
}

.titleName{
  font-size: 5rem;
  margin-bottom: 0.5rem;
  text-align: center;

  animation: fadeInDown 1.2s ease-out;
  color: #003366; 
}

.Homehero p {
  font-size: 1.5rem; 
  margin-bottom: 2rem;
}


@media (max-width: 968px) {
  


  .Homehero h1 {
    font-size: 3.5rem;
  }

  .titleName{
    font-size: 3.5rem;
  }

  .Homehero p {
    font-size: 1.2rem;
  }



.info-bubbles {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 100px;
  padding-left: 150px;
  padding-right: 150px;
}

}




@media (max-width: 768px) {
 
  .services-section h2 {
    font-size: 2rem;
  }

  .service-item {
    margin-bottom: 20px;
    gap: 190px;
  }
}



.selectionButton {
  margin-top: auto; 
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600; 

}
#Startover {

  position: absolute; 
 
  left: 50%; 
  transform: translate(-50%, -50%); 
}




#headingTe{
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  
}
@media (max-width: 1168px) {
  .getStartedSection{
    margin-top: 12%;
    }
}

@media (max-width: 768px) {
  

  .getStartedSection{
    margin-top: -2%;
    }
    #headingTe{
      text-align: center;
      margin-top: 25%;
      font-size: 2rem;
      font-weight: 900;
    }
    .getStartedContainer{
      margin-top: -12%;
    }

    
.info-bubbles {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 100px;
  padding-left: 0px;
  padding-right: 0px;
}

  }

  

  .home-slider-container {
    position: relative;
    max-width: 1000px;
    margin: 120px auto;
    overflow: visible;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    background: #ffffff;
  }
  
  .home-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    transition: transform 0.5s ease-in-out;
  }
  
  .video, .text-image-slide img {
    max-width: 90%;
    border-radius: 10px;

  }
  
  .text-image-slide {
   
    border-radius: 12px;
    padding: 30px;
 
  }
  
  .text-image-slide h2 {
    font-size: 3rem; /* Large enough for readability, small enough to fit */
    margin-bottom: 10px;
    color: #003366;
 
    margin-bottom: 15px;
  }
  
  .text-image-slide p {
    color: #444;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .home-slider-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;
  }
  
  .home-slider-controls button {
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    border: none;
    font-size: 16px;
    padding: 12px 30px;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    margin: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .home-slider-controls button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }



  @media (max-width: 768px) {
    .home-slider-container {
      margin: 30px, 30px;
      padding: 20px 0; /* Adjust padding to fit mobile screens */
      border-radius: 8px; /* Slightly smaller radius for a softer look */
    }
  
    .home-slide {
      padding: 20px; /* Sufficient padding while conserving space */
    }
  
    .video, .text-image-slide img {
      border-radius: 8px; /* Consistent with the container's border radius */
    }
  
    .text-image-slide {
      padding: 15px; /* Optimal padding for content */
      margin: 0 10px; /* Ensures content does not touch the screen edges */
    }
  
    .text-image-slide h2 {
      font-size: 18px; /* Large enough for readability, small enough to fit */
      margin-bottom: 10px;
    }
  
    .text-image-slide p {
      font-size: 14px; /* Adjusted for mobile readability */
      line-height: 1.5; /* Enhanced line height for better readability */
    }
  }
  
  @media (max-width: 480px) {
    .home-slider-container {
      margin: 20px 20px;
    }
  
    .text-image-slide h2 {
      font-size: 16px; /* Further adjusted for very small screens */
    }
  
    .text-image-slide p {
      font-size: 13px; /* Smaller font size for compact screens */
    }
  
    .home-slider-controls {
      bottom: -30px; /* Position controls a bit closer to the slider */
    }
  
    .home-slider-controls button {
      font-size: 12px; /* Smaller font for the control buttons */
      padding: 8px 16px; /* Reduce padding to save space */
      margin: 0 5px; /* Reduce margin to keep buttons accessible */
    }
  }
  






/* Styles for the info-blobs-section */
.info-blobs-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px; /* Consistent spacing between blobs */
}

.info-blob {
  background: white;
  padding: 20px;
  border-radius: 15px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  width: 30%; /* Equal width for each blob */
  text-align: center;
}

.info-blob h3 {
  color: #0056b3; /* Uniform color for headings */
  margin-bottom: 10px;
}

.info-blob p {
  font-size: 0.9rem;
  color: #666; /* Subtle text color for readability */
  line-height: 1.5;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .info-blob {
    width: 100%; /* Full width on small screens for better readability */
    margin-bottom: 20px;
  }
}














  .tech-support-section {
    text-align: center;
    padding: 40px 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.tech-support-section h3 {
   font-weight: bold;
    color: #003366;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.tech-support-section p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.tech-support-button {
    font-size: 18px;
    color: white;
    background-color: #007bff;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tech-support-button:hover {
    background-color: #0056b3;
}





.feedback-section {
  padding: 60px 10%;
  color: #003366;
  text-align: center;
}

.feedback-title {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #0056b3; /* Darker blue for emphasis */
}

.feedback-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.feedback-block {
  background: white;
  border: 3px solid #0056b3; /* Blue border */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 30%; /* Increased for readability */
  min-width: 300px; /* Adjusted for smaller screens */
  position: relative; /* Enables positioning */
}

.feedback-block h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #0056b3; /* Matching border color */
  margin-bottom: 15px; /* Separates the name from the content */
}

.feedback-block p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6; /* Improved for readability */
}

.write-review-btn {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.write-review-btn:hover {
  background-color: #003d82; /* Darker shade on hover */
}

@media (max-width: 768px) {
  .feedback-section {
    padding: 40px 5%;
  }

  .feedback-title {
    font-size: 2.5rem;
  }

  .feedback-block {
    max-width: 100%; /* Full width on smaller screens */
  }
}





.servicehomepagesection-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

.servicehomepagesection-text {
  flex: 1;
  max-width: 40%;
  padding: 20px;
  text-align: left;
}

.servicehomepagesection-text h2 {
  font-size: 4.5rem;
  color: #003366;
  margin-bottom: 20px;
  position: relative;
}

.servicehomepagesection-text h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 5px;
  background: #1a8cff;
}

.servicehomepagesection-text p {
  font-size: 1.2rem;
  color: #4a4a4a;
  line-height: 1.6;
}

.servicehomepagesection-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  flex: 1;
  max-width: 55%;
}

.servicehomepagesection-item {
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease;
}

.servicehomepagesection-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.servicehomepagesection-icon {
  font-size: 3.5rem;
  color: #1a8cff;
  margin-bottom: 20px;
}

.servicehomepagesection-item h3 {
  font-size: 1.8rem;
  color: #003366;
  margin-bottom: 15px;
}

.servicehomepagesection-item p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.servicehomepagesection-btn {
  background-color: #1a8cff;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.servicehomepagesection-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 1024px) {
  .servicehomepagesection-text h2 {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .servicehomepagesection-content {
    flex-direction: column;
  }

  .servicehomepagesection-text {
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .servicehomepagesection-text h2::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .servicehomepagesection-container {
    max-width: 100%;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .servicehomepagesection-text h2 {
    font-size: 3rem;
  }

  .servicehomepagesection-item {
    padding: 20px;
  }
}
