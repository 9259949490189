.accordion-container {
    max-width: 1100px;
    margin: 20px auto;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .accordion-item {
    border-top: 1px solid transparent;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #003366;
    font-size: 22px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .accordion-header:hover {
    background-color: #e0eaff;
  }
  
  .accordion-content {
    padding: 0 20px;
    height: 0;
    overflow: hidden;
    transition: all 0.6s ease;
  }
  
  .accordion-content.open {
    padding: 20px 20px;
    height: auto;
  }
  
  .accordion-content p {
    color: #4a4a4a;
    font-size: 17px;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .accordion-container {
      width: 85%;
    }
    .accordion-header {
      font-size: 20px;
    }
    .accordion-content p {
      font-size: 15px;
    }
  }
  