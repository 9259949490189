/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f7fa;
  color: #333;
  line-height: 1.6;
}

/* ClientViewBlog Styles */
.blogs-container {
  max-width: 1200px;
  margin: 50px auto 0;
  padding: 40px 20px;
}

.blogs-header {
  text-align: center;
  margin-bottom: 50px;
}

.blogs-title {
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: -1px;
}

.blogs-subtitle {
  font-size: 1.2rem;
  color: #7f8c8d;
  max-width: 600px;
  margin: 0 auto;
}

.blog-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.blog-search-bar {
  width: 50%;
  padding: 15px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.blog-search-bar:focus {
  outline: none;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  width: 55%;
}

.blog-category-dropdown {
  margin-left: 20px;
  padding: 15px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.blog-category-dropdown:hover {
  background-color: #2980b9;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}

.blog-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-content {
  padding: 25px;
}

.blog-card-content h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 1.3;
}

.blog-preview {
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 20px;
  line-height: 1.6;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 20px;
}

.blog-tags {
  font-style: italic;
}

.read-more-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: #1976d2;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.read-more-button:hover {
  background-color: #0a345e;
  transform: translateY(-2px);
}

.blog-header-title {
  font-size: 3rem;
  color: #1976d2;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
  font-weight: 700;
}

.show-more-container {
  text-align: center;
  margin-top: 40px;
}

.show-more-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.show-more-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* BlogDetail Styles */
.blog-detail-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 20px;
}

.blog-detail-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.blog-detail-header {
  position: relative;
}

.blog-image-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.blog-detail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-title-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
}

.blog-detail-title {
  margin: 0;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.blog-detail-content {
  padding: 40px;
  font-size: 1.1rem;
  line-height: 1.8;
}

.blog-detail-footer {
  padding: 20px 40px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

/* Image positioning classes */
.image-left { float: left; margin-right: 20px; }
.image-center { margin: 0 auto; }
.image-right { float: right; margin-left: 20px; }

/* Image sizing classes */
.size-small { width: 25%; }
.size-medium { width: 50%; }
.size-large { width: 100%; }

/* Title color classes */
.text-black { color: #000; }
.text-red { color: #e74c3c; }
.text-blue { color: #3498db; }
.text-green { color: #2ecc71; }

/* Title size classes */
.size-medium { font-size: 2rem; }
.size-large { font-size: 2.5rem; }
.size-xlarge { font-size: 3rem; }

/* Responsive adjustments */
@media (max-width: 768px) {
  .blogs-title {
    font-size: 2.5rem;
  }

  .blog-search-bar {
    width: 70%;
  }

  .blog-grid {
    grid-template-columns: 1fr;
  }

  .blog-image-container {
    height: 300px;
  }

  .blog-detail-content {
    padding: 20px;
  }

  .blog-detail-footer {
    padding: 15px 20px;
  }
}

@media (max-width: 480px) {
  .blogs-title {
    font-size: 2rem;
  }

  .blog-search-bar {
    width: 100%;
  }

  .blog-category-dropdown {
    margin-top: 15px;
    width: 100%;
  }

  .blog-card-content h2 {
    font-size: 1.3rem;
  }

  .blog-preview {
    font-size: 0.9rem;
  }

  .blog-detail-title {
    font-size: 1.8rem;
  }

  .blog-detail-content {
    font-size: 1rem;
    padding: 20px;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.blog-card, .blog-detail-container {
  animation: fadeIn 0.5s ease-in-out;
}
