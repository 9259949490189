.msg-btn {
    position: fixed;
    bottom: 100px; /* Positioned above the help button */
    right: 20px; /* Aligns with the help button */
    width: 50px;
    height: 50px;
    background-color: #6e8efb; /* Matching the help button */
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 988;
  }
  
  .msg-btn:hover {
    background-color: #4e6abf; /* Slightly darker shade on hover */
  }
  