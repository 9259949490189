/* Navigation Bar Styles */
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  .scrolled {
    background-color: #ffffff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 2rem;
  }
  
  .logo img {
    height: 60px;
    transition: height 0.3s ease-in-out;
  }
  
  .scrolled .logo img {
    height: 55px;
  }
  
  /* Navigation Links */
  .nav-links {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .nav-links a {
    color: #333;
    text-decoration: none;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
  }
  
  .nav-links a svg {
    margin-right: 8px;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .dropdown button {
    background: none;
    border: none;
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: bold;
    font-weight: bold;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    font-size: 1.2rem;
    font-weight: bold;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font-weight: bold;
    border-radius: 8px;
  }
  
  .dropdown-content a {
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-weight: bold;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .nav-links a:hover,
  .nav-links a.active {
    color: #0066cc;
  }
  
  /* Quote Button */
  .quote-btn {
    background-color: #0066cc;
    font-size: 1.1rem;
    color: #ffffff;
    border-radius: 20px;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .quote-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Side Navigation Toggle Button */
  .side-nav-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 2rem;
    color: #333;
    cursor: pointer;
  }
  

  @media (max-width:   1075px) {
    .nav-links a {
      padding: 0.9rem;
      font-size: 0.9rem;


    }

  
  
  /* Quote Button */
  .quote-btn {
    background-color: #0066cc;
    font-size: 0.9rem;
    color: #ffffff;
    border-radius: 20px;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .dropdown button {
    background: none;
    border: none;
    color: #333;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: bold;
   
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    font-size: 10.9rem;
    font-weight: bold;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font-weight: bold;
    border-radius: 8px;
  }
  
  .dropdown-content a {
    color: #333;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-weight: bold;
  }
  
  
  }
  /* Mobile Navigation Styles */
  @media (max-width: 768px) {
    .side-nav-toggle {
      display: block;
    }
  
    .nav-links {
      position: fixed;
      top: 0;
      left: -100%;
      width: 70%;
      height: 100%;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      flex-direction: column;
      align-items: flex-start;
      padding-top: 2rem;
      transition: left 0.3s ease;
    }
  
    .nav-links.open {
      left: 0;
    }
  
    .nav-links a {
      padding: 1rem;
      font-size: 1.1rem;
    }
  
    .dropdown-content {
      position: relative;
    }
    .dropdown button {
      background: none;
      display: none;
      margin-left: 25px;
      border: none;
      color: #333;
      font-size: 1.1rem;
      font-weight: bold;
      cursor: pointer;
      padding: 0.5rem 1rem;
  
      align-items: center;
      justify-content: space-between;
  
      font-weight: bold;
     
    }
    
    .dropdown-content {
      display: none;
    
      background-color: #f9f9f9;
      font-size: 10.9rem;
      font-weight: bold;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      font-weight: bold;
      border-radius: 8px;
    }
    
    .dropdown-content a {
      color: #333;
      font-size: 0.9rem;
      font-weight: bold;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-weight: bold;
    }
    
  }
  
  /* Animation for spinning effect */
@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  /* Logo image hover effect */
  .logo img:hover {
    animation: spin 0.3s linear ;
    transform: scale(1.3);
  }

  button {
     /* padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;  */
    background: linear-gradient(45deg, #6e8efb, #88a2f9); 
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
 
    letter-spacing: 1px;
    transition: background 0.3s ease;
  }
  
  button:hover {
     background: linear-gradient(45deg, #5a7bda, #274acc); 
    color: white;
  }





/* .navbar {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .alert-bar {
    font-size: 0.9rem;
    padding: 0.4rem;
  }
} */
