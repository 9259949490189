.full-width-svg {
    width: 100%;
    overflow: hidden;
    padding-top: 100px;
     background-color: white; 
}

.full-width-svg img {
    width: 100%;
    height: auto;
    display: block;
}

/* Responsive aspect ratio for A4 size (1:1.414) */
@media screen and (min-width: 600px) {
    .full-width-svg {
        /* padding-top: 141.4%; */
    }
}
