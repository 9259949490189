/* footer.css */
.footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
   
  }
  
  .footer-logo {
    max-height: 50px;
    margin-bottom: 20px;
  }
  
  .footer-links {
    margin-bottom: 20px;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.1rem;
  }
  
  .footer-links a:hover {
    color: #0066cc;
  }
  
  .footer-version,
  .footer-copy {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    /* .footer-links a {
      display: block;
      margin: 10px 0;
    } */
  }
  


  


  .app-download-section {
    text-align: center;
    padding: 50px 0;

    margin-top: 50px;
  }
  
  .app-download-section h2 {
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .app-download-section p {
    margin-bottom: 30px;
  }
  
  .download-buttons {
    display: inline-flex;
    gap: 20px;
  }
  
  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .download-button i {
    margin-right: 10px;
  }
  
  .download-button.apple {
    background-color: #007bff;
  }
  
  .download-button.android {
    background-color: #34a853;
  }
  
  .download-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }


  .social-media-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-media-icons a {
    color: white; /* Adjust the color to fit your footer's theme */
    font-size: 24px; /* Adjust the size as needed */
    transition: color 0.3s ease;
  }
  
  .social-media-icons a:hover {
    color: #0066cc; /* Change hover color as desired */
  }
  