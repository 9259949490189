/* HelpPopup.css */

.help-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 250px;
    background-color: rgb(255, 255, 255);
    opacity: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px;
    z-index: 1000;
  }
  
  .help-popup .close-btn {
    background: none;
    color: #333;
    border: none;
    font-size: 24px;
    cursor: pointer;
    float: right;
  }
  
  .help-popup h4 {
    color: #333;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .help-popup p {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .help-popup ul {
    list-style: inside;
    padding-left: 0;
    margin-top: 10px;
  }
  
  .help-popup ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
.help-link {
    display: block;
    margin-top: 10px;
    color: #6e8efb;
    text-decoration: none;
  }
  
  .help-link:hover {
    text-decoration: underline;
  }
  
  .slider-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .slider-controls button {
  
    border: 1px solid #ddd;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .slider-controls button:hover {
    background-color: #f0f0f0;
  }/* Rest of your existing styles */

.help-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #6e8efb;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  display: flex;
}

.help-btn.hide {
  display: none;
}



.help-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 250px;
  background-color: rgb(255, 255, 255);
  opacity: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  z-index: 1000;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}


.help-popup.open {
  transform: scale(1);
  
}

