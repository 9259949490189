.updates-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .update {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .update-info {
    white-space: pre-wrap; /* This will preserve line breaks and spaces */
  }