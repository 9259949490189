
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, button, input, textarea {
     /*font-family: 'Roboto', sans-serif;*/
     font-family: 'Poppins', sans-serif; 
  }

  .WebDevelopment {
    text-align: left;

  }

  /* Hero section styles */
.hero-section {
    display: flex;
    align-items: center;
    height: 90vh;
    padding: 0 10%;
    background: #ffffff;
  }

  .hero-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    justify-content: center;
    padding-right: 5%;
  }

  .hero-section h1 {
    font-size: 4rem;
    color: #0056b3;
    margin-bottom: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .hero-section p {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 3rem;
    line-height: 1.8;
    max-width: 500px;
  }

  .hero-buttons {
    display: flex;
    gap: 1rem;
  }



  .btn-primary {
    background-color: #0066cc;
    color: #fff;
  }

  .btn-secondary {
    background-color: transparent;
    color: #0066cc;
    border: 2px solid #0066cc;
  }

  .btn:hover {
    transform: scale(1.05); /* Slight scale for interaction */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .btn:active {
    transform: scale(0.95); /* Slight shrink effect on click */
  }

  /* Hero image styles */
  .hero-image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end; /* Align image to the right */
  }

  .hero-image {
    max-width: 50%; /* Control size of the image */
    object-fit: contain; /* Ensure full image is visible */
    position: absolute;
    right: 8%;
    bottom: 12%;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
     .hero-section {
      flex-direction: column-reverse;
      justify-content: center;
      text-align: center;
      padding: 4rem 5%;
    }

    .hero-text-container, .hero-image-container {
      flex-basis: 100%;
      order: 1;
    }

    .hero-image {
      max-width: 80%;
      margin: 2rem auto 3rem;
      position: relative;
      display: none;
    }

        .hero-image-container {
        display: none;
        }


    .hero-section h1 {
      font-size: 3rem;
    }

    .hero-buttons {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }








.info-section {
    padding: 2rem 10%;
    /* background-color: #f7f9fc; */
    overflow: hidden;
    background: #a0b2c84e;
  }

  .info-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6rem;
    gap: 4rem;
    position: relative;
  }

  .info-block.middle-block {

  }

  .info-image-container {
    flex: 1;
    /* background-color: white; */
    overflow: hidden;
    border-radius: 20px;
    transition: transform 0.3s ease-out;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    align-items: center;
    margin: auto;

  }

  .info-image-container:hover {
    transform: scale(1.07); /* Enhanced zoom effect on hover */
  }

  .info-image {
    height: 100%;
    width: 100%;
    /* background-color: white; */
    height: auto;
    border-radius: 20px;
    transition: transform 0.3s ease-out;
    align-self: center;
    margin: auto;
    /* margin-left: 25%; */
  }

  .info-text-container {
    flex: 1;
    padding: 3rem;
    background: white;
    border-radius: 20px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease; /* Transition for hover effects */
  }

  .info-text-container:hover {
    transform: translateY(-10px); /* Subtle lift effect on hover */
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.15);
  }

  .info-text-container h2 {
    color: #002244; /* Darker blue for contrast */
    margin-bottom: 1.5rem;
    font-size: 2.8rem;
  }

  .info-text-container p {
    color: #4a4a4a;
    font-size: 1.4rem;
    line-height: 1.8;
  }

  /* Responsive adjustments */
  @media (max-width: 960px) {
     .info-block, .info-block.middle-block {
      flex-direction: column;
      text-align: center;
    }

    .info-image-container, .info-text-container {
      width: 90%;
      margin: auto;

    }

  .info-image {
    /* height: 50%;
    width: 50%;
    background-color: white; */
    height: auto;
    border-radius: 20px;
    transition: transform 0.3s ease-out;
    align-self: center;
    margin: auto;
    margin-left: 0%;
  }

    .info-block.middle-block .info-text-container {
      order: 2;
    }


  .info-text-container h2 {
    color: #002244; /* Darker blue for contrast */
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
  }

  }

/* Services Section Enhanced Styles */
.services-section {
    padding: 5rem 10%;
    background-color: #f8f8fa; /* Slightly off-white for depth */
    text-align: center;
  }

  .services-section h2 {
    font-size: 3.5rem;
    color: #003366; /* Darker blue for a more professional look */
    margin-bottom: 4rem;
    letter-spacing: -1px; /* Subtle letter spacing for elegance */
  }

  .service-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2.5rem;
  }

  .service-card {
    background-color: white;
    padding: 2.5rem;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Softer initial shadow */
  }

  .backWhite{
    background-color: white;
  }

  .service-card:hover {
    transform: translateY(-10px); /* Lift effect on hover */
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
  }

  .service-icon {
    font-size: 2rem;
      color: #1a8cff;
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
  }

  .service-card:hover .service-icon {
    transform: rotate(10deg); /* Slight rotation for dynamic interaction */
  }

  .service-card h3 {
    font-size: 2rem;
    color: #004785;
    margin-bottom: 1rem;
  }

  .service-card p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
  }

  /* Responsive adjustments for better mobile experience */
  @media (max-width: 768px) {
     .services-section {
      padding: 4rem 5%;
    }

    .services-section h2 {
      font-size: 3rem;
    }

    .service-cards-container {
      grid-template-columns: 1fr;
    }

    .service-card {
      padding: 2rem;
    }
  }

/* Responsive styles for hero section */
@media (max-width: 768px) {
     .hero-text-container {

      padding: 0 5%;
    }

    .hero-image-container {

      justify-content: center;
      position: relative;
      right: auto;
      bottom: auto;
    }

    .hero-image {
      max-width: 90%;
    }
  }


















  
  .WebDevelopment {
    text-align: left;
  }
  
  .hero-section {
    display: flex;
    align-items: center;
    height: 100vh;
    padding: 0 10%;
    background: linear-gradient(to bottom, #e0eaff, #f0f4ff);
    overflow: hidden;
    position: relative;
  }
  
  .hero-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .hero-section h1 {
    font-size: 3.5rem;
    color: #0056b3;
    font-weight: 700;
    line-height: 1.2;
  }
  
  .hero-section p {
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .hero-buttons {
    display: flex;
    gap: 1rem;
  }
  
  .btn {
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  
  .btn:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .btn:active {
    transform: scale(0.95);
  }
  
  .hero-image-container {
    flex: 1;
    display: flex;
    justify-content: right; /* Align image */
  }
  
  .hero-image {
    max-width: 50%; /* Contain image size */
    border-radius: 10px;
    object-fit: contain;
  }
  
  .services-section {
    padding: 5rem 10%;
    background-color: #f8f8fa;
    text-align: center;
  }
  
  .services-section h2 {
    font-size: 3rem;
    color: #003366;
    margin-bottom: 3rem;
  }
  
  .service-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2.5rem;
  }
  
  .service-card {
    background: white;
    padding: 2.5rem;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
  }
  
  .service-icon {
    font-size: 2rem;
    color: #1a8cff;
    margin-bottom: 1.5rem;
  }
  
  .service-card:hover .service-icon {
    transform: rotate(10deg);
  }
  
  .service-card h3 {
    font-size: 2rem;
    color: #004785;
    margin-bottom: 1rem;
  }
  
  .service-card p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
  }
  .benefits-section {
    padding: 4rem 10%;
    background-color: #f0f0ff; /* Subtle blue background */
    text-align: center;
    border-radius: 10px; /* Rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.benefits-section h2 {
    font-size: 3rem; /* Larger heading size */
    color: #051733; /* Darker blue for emphasis */
    margin-bottom: 2rem; /* Space between heading and list */
    text-decoration: underline; /* Underline for emphasis */
}

.benefits-list {
    list-style: none; /* Remove default list styling */
    padding: 0;
    display: flex; /* Flexbox layout for inline items */
    flex-wrap: wrap; /* Allow wrapping on small screens */
    justify-content: space-around; /* Distribute evenly */
    gap: 1.5rem; /* Space between items */
}

.benefits-item {
    background-color: #ffffff; /* White background for contrast */
    padding: 1.5rem 2rem; /* Padding for readability */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 300px; /* Maximum width to avoid large items */
    text-align: left; /* Left-align text */
    transition: all 0.3s ease; /* Smooth transitions */
}

.benefits-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.benefits-item p {
    font-size: 1rem; /* Smaller font size for readability */
    line-height: 1.6; /* Increased line height */
    color: #4a4a4a; /* Dark gray text */
}

.benefits-item-icon {
    font-size: 2rem; /* Large icon size */
    color: #1a8cff; /* Matching blue */
    margin-bottom: 1rem; /* Space below icon */
}

@media (max-width: 768px) {
    .benefits-list {
        flex-direction: column; /* Vertical stack for mobile */
        align-items: center; /* Center-align items */
    }
}
.clients-section {
  padding: 4rem 10%;
  background-color: #f8f8fa; /* Slightly off-white for subtle contrast */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.clients-container {
  display: grid; /* Grid layout for better responsiveness */
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); /* Columns adjust automatically */
  gap: 1.5rem; /* Space between items */
  justify-items: center; /* Center items horizontally */
}

.client {
  background: white; /* White background for contrast */
  padding: 2rem; /* Increased padding for spacing */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Deeper shadow */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.client:hover {
  transform: translateY(-5px); /* Subtle lift effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.client-logo {
  max-width: 100%; /* Contain image within card */
  border-radius: 5px; /* Rounded corners for the logo */
  margin-bottom: 1rem; /* Space between logo and text */
}

.client p {
  font-size: 1rem; /* Font size adjustment */
  color: #4a4a4a; /* Dark gray text */
  line-height: 1.6; /* Line height for readability */
  text-align: center; /* Center text */
}

@media (max-width: 768px) {
  .clients-section {
      padding: 3rem 5%; /* Adjust padding for smaller screens */
  }

  .clients-container {
      grid-template-columns: 1fr; /* Single column layout for mobile */
  }
}
.cta-section {
  padding: 4rem 10%;
  text-align: center;
  background-color: #f7f8fc; /* Light background for contrast */
}

.cta-section h2 {
  color:#003366;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem; /* Space between steps */
  margin-top: 2rem; /* Space between text and steps */
}

.step {
  background: white;
  padding: 1.5rem;
  border-radius: 10px; /* Smooth edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  text-align: left; /* Better readability */
}

.step-number {
  background-color: #0056b3; /* Blue for emphasis */
  color: white;
  font-size: 1.2rem; /* Larger for visibility */
  padding: 0.5rem 1rem; /* Better spacing */
  border-radius: 50%; /* Circular shape */
  display: inline-block; /* Consistent shape */
  margin-bottom: 0.5rem; /* Space below number */
}

.step h3 {
  font-size: 1.5rem; 
  color: #004580; 
  margin-bottom: 0.8rem; 
}

.step p {
  font-size: 1rem; 
  line-height: 1.5; 
}

on:hover {
  background-color: #003d82; 
}


@media (max-width: 768px) {
  .steps-container {
    grid-template-columns: 1fr; 
  }
}

  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 4rem 5%;
    }
  
    .hero-text-container, .hero-image-container {
      flex-basis: 100%;
      order: 1;
    }
  
    .hero-image {
      max-width: 80%;
      margin: 2rem auto 3rem;
      position: relative;
    }
  
    .services-section {
      padding: 4rem 5%;
    }
  
    .services-section h2 {
      font-size: 2.5rem;
    }
  
    .service-cards-container {
      grid-template-columns: 1fr;
    }
  
    .service-card {
      padding: 2rem;
    }
  }
  
    
  @media (max-width: 1056px) {
    .hero-image {
     display: none;
    }
  }