/* Main Container */
.features-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.features-container h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

/* Search Bar */
.features-search-bar {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.features-search-bar input {
  width: 100%;
  max-width: 500px;
  padding: 12px 20px;
  border-radius: 25px;
  border: 2px solid #007bff;
  font-size: 1.1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-search-bar select {
  padding: 12px;
  border-radius: 25px;
  border: 2px solid #007bff;
  font-size: 1.1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Category Title */
.category-title {
  font-size: 2rem;
  color: #333;
  margin-top: 30px;
  text-align: left;
  padding-left: 10px;
}

/* Category Grid */
.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  padding: 0 10px;
}

/* Individual Feature Card */
.feature-card {
  background-color: white;
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Feature Icon */
.service-icon {
  font-size: 3.5rem;
  color: #007bff;
  margin-bottom: 15px;
}

/* Feature Title and Description */
.feature-title {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

/* Feature Detail Button */
.feature-details-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 25px;
  font-size: 1rem;
  transition: background-color 0.2s;
  cursor: pointer;
}

.feature-details-button:hover {
  background-color: #0056b3;
}

/* Feature Modal */
.feature-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: 90%; /* Adjust width for mobile */
}

.modal-close-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-close-btn:hover {
  background-color: #0056b3;
}

/* Responsive Adjustments for Mobile */
@media screen and (max-width: 768px) {
  .features-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .features-search-bar input {
    padding: 10px 15px;
    font-size: 1rem;
  }

  .features-search-bar select {
    padding: 10px;
    font-size: 1rem;
  }

  .feature-card {
    padding: 20px;
  }

  .service-icon {
    font-size: 3rem;
  }

  .feature-title {
    font-size: 1.2rem;
  }

  .feature-description {
    font-size: 1rem;
  }

  .feature-details-button {
    padding: 8px 20px;
    font-size: 0.9rem;
  }
}
