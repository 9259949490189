.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 120px;
}

.client-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
}

.form-input, .form-select, .form-textarea, .form-button {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
}

.form-textarea {
  height: 120px;
  resize: vertical;
}

.form-button {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-title {
  color: #007bff;
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .client-form {
    padding: 30px;
    gap: 15px;
  }

  .form-input, .form-select, .form-textarea {
    padding: 12px;
  }

  .form-button {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .client-form {
    padding: 20px;
  }
}
