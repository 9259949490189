/* ReferralPage.css */
.referral-page {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --text-color: #34495e;
    --background-color: #f8f9fa;

    --shadow-color: rgba(0, 0, 0, 0.1);
  
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
  
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .referral-page .referral-content {
    background-color: rgb(255, 255, 255);
    padding: 40px;
    margin-top: 5%;
    border-radius: 12px;
    box-shadow: 0 4px 20px var(--shadow-color);
  }
  @media (max-width: 768px) {
    .referral-page .referral-content {
      background-color: rgb(255, 255, 255);
      padding: 40px;
      margin-top: 15%;
      border-radius: 12px;
      box-shadow: 0 4px 20px var(--shadow-color);
    }
  }
  
  .referral-page h1,
  .referral-page h2,
  .referral-page h3,
  .referral-page h4 {
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .referral-page h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .referral-page .step-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .referral-page .step {
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: var(--background-color);
    border-radius: 20px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .referral-page .step.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  .referral-page .referral-info {
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .referral-page .referral-info ul {
    padding-left: 20px;
  }
  
  .referral-page .referral-info li {
    margin-bottom: 10px;
  }
  
  .referral-page .referrals-container {
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .referral-page .referral-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px var(--shadow-color);
    position: relative;
  }
  
  .referral-page .input-group {
    display: grid;
    gap: 15px;
  }
  
  .referral-page .input-wrapper {
    position: relative;
  }
  
  .referral-page .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
  }
  
  .referral-page input {
    width: 100%;
    padding: 12px 12px 12px 35px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .referral-page input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  .referral-page button {
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .referral-page .add-referral-btn,
  .referral-page .next-step-btn,
  .referral-page .submit-btn {
    background-color: var(--secondary-color);
    color: white;
    width: 100%;
    margin-top: 20px;
  }
  
  .referral-page .remove-referral-btn {
    background-color: #e74c3c;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .referral-page .back-btn {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .referral-page .button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .referral-page .button-group button {
    flex: 1;
  }
  
  .referral-page .terms-container {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
  }
  
  .referral-page .terms-container input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 5px;
  }
  
  .referral-page .terms-container label {
    font-size: 14px;
    line-height: 1.4;
  }
  
  .referral-page .review-step,
  .referral-page .confirmation-step {
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .referral-page .review-referral {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px var(--shadow-color);
  }
  
  .referral-page .confirmation-icon {
    font-size: 48px;
    color: var(--secondary-color);
    display: block;
    margin: 0 auto 20px;
  }
  
  .referral-page .start-over-btn {
    background-color: var(--primary-color);
    color: white;
    display: block;
    width: 200px;
    margin: 20px auto 0;
  }
  
  .referral-page .testimonials {
    margin-top: 40px;
    text-align: center;
  }
  
  .referral-page .testimonial {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px var(--shadow-color);
  }
  
  .referral-page .testimonial p {
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .referral-page .testimonial cite {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .referral-page .referral-content {
      padding: 20px;
    }
  
    .referral-page h1 {
      font-size: 2em;
    }
  
    .referral-page .step-indicator {
      flex-direction: column;
      align-items: center;
    }
  
    .referral-page .step {
      width: 100%;
      margin-bottom: 10px;
      padding: 15px;
    }
  
    .referral-page .referral-info,
    .referral-page .review-step,
    .referral-page .confirmation-step {
      text-align: center;
    }
  
    .referral-page .referral-info ul {
      padding-left: 0;
      list-style-position: inside;
    }
  
    .referral-page .input-group {
      gap: 10px;
    }
  
    .referral-page .button-group {
      flex-direction: column;
    }
  
    .referral-page .button-group button {
      width: 100%;
    }
  
    .referral-page .terms-container {
      flex-direction: column;
      align-items: center;
    }
  
    .referral-page .terms-container input[type="checkbox"] {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .referral-page .terms-container label {
      text-align: center;
    }
  
    .referral-page .testimonial {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .referral-page .referral-content {
      padding: 15px;
    }
  
    .referral-page h1 {
      font-size: 1.8em;
    }
  
    .referral-page .step {
      font-size: 14px;
    }
  
    .referral-page input,
    .referral-page button {
      font-size: 14px;
    }
  
    .referral-page .remove-referral-btn {
      font-size: 12px;
      padding: 3px 8px;
    }
  
    .referral-page .testimonial p {
      font-size: 14px;
    }
  
    .referral-page .testimonial cite {
      font-size: 12px;
    }
  
    .referral-page .step-indicator {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .referral-page .step {
      flex: 0 0 auto;
      width: auto;
      margin: 5px;
      padding: 8px 15px;
      font-size: 12px;
    }
  }
  .terms-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0;
    margin-top: 4%;
}

.terms-container label {
    margin-right: 10px;
    margin-top: 5px;
    font-size: 1rem;
}

.terms-container input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
