.smeadit-feature-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .smeadit-modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 15px;
    width: 50%;
    max-width: 600px; /* Maximum width */
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    text-align: center;
    transition: transform 0.3s ease-in-out;
    transform: scale(0.9); /* Initial scale */
  }
  
  .smeadit-feature-modal.show .smeadit-modal-content {
    transform: scale(1); /* Scale up when shown */
  }
  
  .smeadit-modal-close-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
  }
  
  .smeadit-modal-close-btn:hover {
    background-color: #0056b3;
    color: white;
    transform: translateY(-2px); /* Slight lift */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .smeadit-modal, h2 {
    color: #007bff;
    margin-bottom: 15px;
    font-size: 1.8rem; /* Larger font size */
  }
  
  /* .smeadit-modal, p {
    color: #333;
    font-size: 1.2rem;
    line-height: 1.6;
  } */
  