.containers {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 100px 15px 0;
  background: #f7f9fc;
}

.quote-form-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.quote-form-container h2 {
  color: #102a43;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
}

.quote-form-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quote-form-container input,
.quote-form-container select,
.quote-form-container textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #d0d9e8;
  border-radius: 8px;
  background: #f7f9fc;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.quote-form-container input:focus,
.quote-form-container select:focus,
.quote-form-container textarea:focus {
  border-color: #6875f5;
  background: #fff;
  box-shadow: 0 0 0 4px rgba(104, 117, 245, 0.2);
}

.quote-form-container input:hover,
.quote-form-container select:hover,
.quote-form-container textarea:hover {
  background: #fff;
  border-color: #6875f5;
}

.quote-form-container button {
  width: 100%;
  padding: 15px 30px;
  background-color: #6875f5;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}

.quote-form-container button:hover {
  background-color: #5a67d8;
  box-shadow: 0 12px 24px rgba(90, 103, 216, 0.2);
}

.service-type-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.service-type-options input[type="radio"] {
  display: none;
}

.service-type-options label {
  flex: 1;
  text-align: center;
  background-color: #f7f9fc;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  border: 1px solid #d0d9e8;
}

.service-type-options label:hover,
.service-type-options input[type="radio"]:checked + label {
  background-color: #6875f5;
  color: white;
  border-color: #6875f5;
}

.booking-container {
  margin: 20px 0;
}

.booking-preference {
  margin-bottom: 20px;
}

.time-slots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.time-slot {
  flex: 1 0 calc(33.333% - 10px);
  padding: 10px 15px;
  border: 1px solid #d0d9e8;
  background-color: #f7f9fc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.time-slot.selected {
  background-color: #6875f5;
  color: white;
  border-color: #6875f5;
}

.time-slot:disabled {
  background-color: #e2e8f0;
  cursor: not-allowed;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.popup-inner p {
  margin: 0;
  font-size: 1.2em;
  color: #102a43;
}

.quote-form-container textarea {
  height: 150px;
  resize: vertical;
}

.quote-form-container select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23102a43' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  padding-right: 40px;
}

.quote-form-container .service-type-options label {
  display: inline-block;
  padding: 10px 20px;
}

.quote-form-container .booking-preference label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.quote-form-container .booking-preference input[type="date"],
.quote-form-container .booking-preference input[type="time"] {
  width: 100%;
  margin-top: 5px;
}

.quote-form-container p {
  font-size: 0.9rem;
  color: #4a5568;
  margin-top: 20px;
}

.quote-form-container ul {
  padding-left: 20px;
  margin-top: 10px;
}

.quote-form-container li {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .containers {
    padding-top: 110px;
  }

  .quote-form-container {
    padding: 30px 20px;
  }

  .service-type-options {
    flex-direction: column;
    gap: 10px;
  }

  .time-slot {
    flex: 1 0 calc(50% - 10px);
  }

  .quote-form-container h2 {
    font-size: 2rem;
  }

  .quote-form-container input,
  .quote-form-container select,
  .quote-form-container textarea {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .containers {
    padding-top: 120px;
  }

  .quote-form-container {
    padding: 20px 15px;
  }

  .time-slot {
    flex: 1 0 100%;
  }

  .quote-form-container h2 {
    font-size: 1.8rem;
  }
}
