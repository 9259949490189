/* CSS Variables for dynamic styling */
:root {
  --cw-base-font-size: 16px;
  --cw-heading-color: #333333;
  --cw-subheading-color: #555555;
  --cw-body-color: #333333;
  --primary-color: #4a90e2;
  --primary-hover: #357abd;
  --secondary-color: #fafafa;
  --accent-color: #f39c12;
}

/* ------------------------------ */
/* Global and Container Styling   */
/* ------------------------------ */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #f2f2f2;
}

.cw-container-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
}

/* Centering the main container to prevent it from being hidden in the navbar */
.mode-select-screen,
.check-design-container,
.cw-container {
  margin: 20px auto;
  max-width: 700px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* ------------------------------ */
/* Header Styles for Submission   */
/* ------------------------------ */
.cw-header {
  background: linear-gradient(135deg, var(--primary-color), #1454a8);
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;
}

.cw-title {
  font-size: 2rem;
  margin: 0;
}

.cw-subheading {
  font-size: 1rem;
  margin: 5px 0 0;
  opacity: 0.9;
}

/* ------------------------------ */
/* Progress Bar                   */
/* ------------------------------ */
.cw-progress-container {
  background: #e0e0e0;
  height: 8px;
  width: 100%;
  margin-top: 20px;
  border-radius: 4px;
}
.cw-progress-bar {
  height: 100%;
  width: 0%;
  background: var(--primary-color);
  transition: width 0.3s ease-in-out;
  border-radius: 4px;
}

/* ------------------------------ */
/* Step Wizard Styling            */
/* ------------------------------ */
.cw-step {
  display: none;
  padding: 30px 40px;
  animation: cwFadeIn 0.5s ease-in-out;
}
.cw-step.cw-active {
  display: block;
}
@keyframes cwFadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.cw-step label {
  display: block;
  margin: 15px 0 5px;
  font-weight: 500;
  color: var(--cw-body-color);
}

.cw-step input[type="text"],
.cw-step input[type="email"],
.cw-step input[type="tel"],
.cw-step input[type="url"],
.cw-step select,
.cw-step textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: inherit;
  transition: border-color 0.3s ease;
}

.cw-step input:focus,
.cw-step select:focus,
.cw-step textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* ------------------------------ */
/* Button Styling                 */
/* ------------------------------ */
.cw-btn-container {
  display: flex;
  justify-content: space-between;
  margin: 30px 40px 30px;
  flex-wrap: wrap;
  gap: 10px;
}
.cw-btn {
  background: var(--primary-color);
  color: #fff;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}
.cw-btn:hover {
  background: var(--primary-hover);
  transform: translateY(-2px);
}
.cw-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}
.cw-btn-sm {
  background: #999;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  margin-left: 10px;
  cursor: pointer;
}
.cw-btn-sm:hover {
  background: #777;
}

/* ------------------------------ */
/* Palette Grid & Custom Colors   */
/* ------------------------------ */
.cw-palette-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}
.cw-palette-box {
  width: calc(33.333% - 10px);
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: border 0.3s ease, transform 0.2s ease;
}
.cw-palette-box:hover {
  transform: scale(1.05);
}
.cw-palette-box.cw-active {
  border-color: var(--primary-color);
}
.cw-palette-swatch {
  height: 50px;
  display: flex;
}
.cw-swatch {
  flex: 1;
}
.cw-palette-label {
  text-align: center;
  padding: 5px;
  background: var(--secondary-color);
  font-size: 0.9rem;
}

/* Custom color pickers */
.cw-custom-colors {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.cw-custom-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ------------------------------ */
/* Pages (Website Structure)      */
/* ------------------------------ */
.cw-page-entry {
  border: 1px solid #ddd;
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px;
  position: relative;
  transition: box-shadow 0.3s ease;
}
.cw-page-entry:hover {
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}
.cw-delete-page {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #d9534f;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background 0.3s ease;
}
.cw-delete-page:hover {
  background: #c9302c;
}

/* ------------------------------ */
/* Font Options & Selection       */
/* ------------------------------ */
.cw-font-label {
  margin-top: 20px;
  display: block;
  font-weight: 600;
}
.cw-font-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.cw-font-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.3s ease, background 0.3s ease;
}
.cw-font-option:hover {
  background: #f7f7f7;
}
.cw-font-option.cw-active {
  border-color: var(--primary-color);
  background: #e9f2ff;
}
.cw-font-option input {
  margin-right: 10px;
}
.cw-font-sample {
  margin-left: 15px;
  font-size: 1rem;
  white-space: nowrap;
}
.cw-font-selected {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.cw-selected-info {
  font-size: 0.95rem;
  margin-right: 10px;
  color: var(--cw-heading-color);
}

/* ------------------------------ */
/* Additional Features            */
/* ------------------------------ */
.cw-features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}
.cw-feature-option {
  flex: 1 1 calc(50% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: var(--secondary-color);
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
  display: flex;
  align-items: center;
}
.cw-feature-option:hover {
  background: #f0f0f0;
  border-color: var(--primary-color);
}
.cw-feature-option input {
  margin-right: 10px;
}

/* ------------------------------ */
/* File Input Styling             */
/* ------------------------------ */
.cw-file-input {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  color: #888;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
  margin-bottom: 15px;
}
.cw-file-input:hover {
  background: #f0f0f0;
  border-color: var(--primary-color);
}
.cw-image-desc {
  margin-top: 5px;
  font-size: 0.9rem;
  color: var(--cw-subheading-color);
}

/* ------------------------------ */
/* Summary Popup Styling          */
/* ------------------------------ */
.cw-summary-container {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}
.cw-summary-title {
  margin: 0 0 15px;
}
.cw-summary-item {
  margin-bottom: 10px;
}
.cw-info {
  font-size: 0.9rem;
  color: #555;
  margin-top: 15px;
}

/* ------------------------------ */
/* Loading Overlay                */
/* ------------------------------ */
.cw-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.cw-loading-spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ------------------------------ */
/* Hover Effects for Check Mode   */
/* ------------------------------ */
.check-design-container input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
  transition: border-color 0.3s ease;
}
.check-design-container input[type="email"]:focus {
  border-color: var(--primary-color);
}

/* ------------------------------ */
/* Found Project Cards Styling    */
/* ------------------------------ */
.foundProjectCard {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.foundProjectCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* ------------------------------ */
/* Responsive Adjustments         */
/* ------------------------------ */
@media (max-width: 600px) {
  .cw-container, .mode-select-screen, .check-design-container {
    margin: 20px;
    padding: 20px;
  }
  .cw-palette-box {
    width: calc(50% - 10px);
  }
  .cw-btn-container {
    flex-direction: column;
  }
  .cw-font-option {
    flex-direction: column;
    align-items: flex-start;
  }
  .cw-features-grid {
    flex-direction: column;
  }
  .cw-file-input {
    padding: 15px;
  }
}