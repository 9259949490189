.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f0f4f8;
    color: #102a43;
    text-align: center;
    padding: 0 20px;
  }
  
  .content {
    max-width: 600px;
    animation: fadeIn 1.5s ease-out;
  }
  
  .content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: scaleUp 1.2s ease-in-out;
  }
  
  .countdown-timer {
    font-size: 1.5rem;
    margin: 20px 0;
    padding: 10px;
    background: #ffffff;
    display: inline-block;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .info-text {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #6e8efb;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* For the countdown timer, you may want to import a library or write a custom script */
  